import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Implementing UPDATE operation";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "implementing-update-operation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#implementing-update-operation",
        "aria-label": "implementing update operation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing UPDATE operation`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#what-you-need"
          }}>{`What you need`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#1-implement-server-side"
          }}>{`1. Implement server-side`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#add-an-exception-handler"
            }}>{`Add an exception handler`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#implement-the-service-method"
            }}>{`Implement the service method`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#3-test-updating-a-blog-post"
          }}>{`3. Test updating a blog post`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#4-test-an-error-case"
          }}>{`4. Test an error case`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#whats-next"
          }}>{`What's next`}</a></p>
      </li>
    </ul>
    <p>{`Previously, we created and read blog posts.
Now, let's implement and make a call to update a blog post.
We'll also learn how to handle an exception with a custom exception handler.`}</p>
    <TutorialSteps current={5} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the following files obtained from previous steps.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/grpc"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/grpc/blog/define-service#6-compile-the-proto-file"
        }}>{`Generated Java code`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogService.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Main.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceTest.java`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "1-implement-server-side",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-implement-server-side",
        "aria-label": "1 implement server side permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Implement server-side`}</h2>
    <p>{`Let's implement the server-side for updating blog posts.
This time, we'll use a custom exception handler.`}</p>
    <h3 {...{
      "id": "add-an-exception-handler",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-an-exception-handler",
        "aria-label": "add an exception handler permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add an exception handler`}</h3>
    <p>{`First, add a custom exception handler for the blog service.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a class for the exception to be thrown when a specified blog post ID doesn't exist.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogNotFoundException.java",
            "filename": "BlogNotFoundException.java"
          }}>{`package example.armeria.server.blog.grpc;

final class BlogNotFoundException extends IllegalStateException {
  BlogNotFoundException(String s) {
      super(s);
  }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add an exception handler class for the blog service.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=GrpcExceptionHandler.java",
            "filename": "GrpcExceptionHandler.java"
          }}>{`package example.armeria.server.blog.grpc;

import com.linecorp.armeria.common.RequestContext;
import com.linecorp.armeria.common.annotation.Nullable;
import com.linecorp.armeria.common.grpc.GrpcExceptionHandlerFunction;

import io.grpc.Metadata;
import io.grpc.Status;

public class GrpcExceptionHandler implements GrpcExceptionHandlerFunction {

  @Nullable
  @Override
  public Status apply(RequestContext ctx, Throwable cause, Metadata metadata) {
    if (cause instanceof IllegalArgumentException) {
        return Status.INVALID_ARGUMENT.withCause(cause);
    }
    if (cause instanceof BlogNotFoundException) {
        return Status.NOT_FOUND.withCause(cause).withDescription(cause.getMessage());
    }

    // The default mapping function will be applied.
    return null;
  }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`Main`}</inlineCode>{` class, bind the `}<inlineCode parentName="p">{`GrpcExceptionHandler`}</inlineCode>{` to our service.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`private static Server newServer(int port) throws Exception {
  final GrpcService grpcService =
     GrpcService.builder()
                .addService(new BlogService())
                .exceptionMapping(new GrpcExceptionHandler()) // Add this
                .build();
  ...
}
`}</code></pre>
      </li>
    </ol>
    <h3 {...{
      "id": "implement-the-service-method",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#implement-the-service-method",
        "aria-label": "implement the service method permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implement the service method`}</h3>
    <p>{`In the `}<inlineCode parentName="p">{`BlogServiceImpl`}</inlineCode>{` class, add a service method for updating a blog post.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogService.java",
        "filename": "BlogService.java"
      }}>{`import example.armeria.blog.grpc.UpdateBlogPostRequest;

public class BlogService extends BlogServiceGrpc.BlogServiceImplBase {
  @Override
  public void updateBlogPost(UpdateBlogPostRequest request, StreamObserver<BlogPost> responseObserver) {
    final BlogPost oldBlogPost = blogPosts.get(request.getId());
    if (oldBlogPost == null) {
      throw new BlogNotFoundException("The blog post does not exist. ID: " + request.getId());
    } else {
      final BlogPost newBlogPost = oldBlogPost.toBuilder()
                .setTitle(request.getTitle())
                .setContent(request.getContent())
                .setModifiedAt(Instant.now().toEpochMilli())
                .build();
      blogPosts.put(request.getId(), newBlogPost);
      responseObserver.onNext(newBlogPost);
      responseObserver.onCompleted();
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "3-test-updating-a-blog-post",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-test-updating-a-blog-post",
        "aria-label": "3 test updating a blog post permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Test updating a blog post`}</h2>
    <p>{`Let's try updating the content of the first blog post.
Add a method like the following.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`@Test
@Order(5)
void updateBlogPosts() throws JsonProcessingException {
  final UpdateBlogPostRequest request = UpdateBlogPostRequest.newBuilder()
              .setId(0)
              .setTitle("My first blog")
              .setContent("Hello awesome Armeria!")
              .build();
  final BlogPost updated = client.updateBlogPost(request);
  assertThat(updated.getId()).isZero();
  assertThat(updated.getTitle()).isEqualTo("My first blog");
  assertThat(updated.getContent()).isEqualTo("Hello awesome Armeria!");
}
`}</code></pre>
    <p>{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</p>
    <h2 {...{
      "id": "4-test-an-error-case",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-test-an-error-case",
        "aria-label": "4 test an error case permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Test an error case`}</h2>
    <p>{`To check that our exception handler is working, let's try updating a post which does not exist.`}</p>
    <ol>
      <li parentName="ol">{`Bind the exception handler to the service for the test server.`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`@RegisterExtension
static final ServerExtension server = new ServerExtension() {
  @Override
  protected void configure(ServerBuilder sb) throws Exception {
    sb.service(GrpcService.builder()
              .addService(new BlogService())
              .exceptionHandler(new GrpcExceptionHandler()) // Add this
              .build());
  }
};
`}</code></pre>
      </li>
      <li parentName="ol">{`Add a test method to update a blog post with an invalid ID, asserting an exception is thrown as expected.`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`@Test
@Order(6)
void updateInvalidBlogPost() {
  final Throwable exception = catchThrowable(() -> {
     client.updateBlogPost(UpdateBlogPostRequest.newBuilder()
              .setId(Integer.MAX_VALUE)
              .setTitle("My first blog")
              .setContent("Hello awesome Armeria!")
              .build());
  });
  final StatusRuntimeException statusException = (StatusRuntimeException) exception;
  assertThat(statusException.getStatus().getCode()).isEqualTo(Code.NOT_FOUND);
  assertThat(statusException)
              .hasMessageContaining("The blog post does not exist. ID: " + Integer.MAX_VALUE);
}
`}</code></pre>
      </li>
      <li parentName="ol">{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</li>
    </ol>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's next`}</h2>
    <p>{`In this step, we've implemented a service method for updating a blog post.
We've also added an exception handler.`}</p>
    <p>{`Next, at `}<a parentName="p" {...{
        "href": "/tutorials/grpc/blog/implement-delete"
      }}>{`Step 6. Implement DELETE`}</a>{`, we'll implement a method
for deleting a blog post and add a `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation Service`}</a>{` to our service.`}</p>
    <TutorialSteps current={5} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      